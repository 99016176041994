<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="2242.000000pt" height="812.000000pt" viewBox="0 0 2242.000000 812.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,812.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M680 4060 l0 -3430 1035 0 1035 0 1 1003 c0 888 2 997 15 962 8 -22
12 -45 9 -53 -2 -7 -1 -11 4 -8 11 7 24 -41 15 -56 -4 -6 -3 -8 2 -5 13 7 108
-279 98 -294 -4 -7 -3 -9 3 -6 12 7 67 -159 57 -175 -4 -6 -3 -8 3 -5 12 8 75
-185 67 -209 -4 -12 -3 -15 3 -7 11 15 98 -241 87 -258 -4 -7 -3 -9 3 -6 12 8
37 -69 27 -85 -4 -6 -3 -8 3 -5 5 3 27 -47 48 -114 l39 -120 130 3 129 3 11
33 c6 18 14 30 18 27 4 -4 4 2 1 14 -3 12 -1 21 6 21 6 0 8 7 4 17 -5 12 -3
14 7 8 9 -5 11 -4 6 4 -10 15 65 241 78 234 5 -3 6 -1 2 6 -8 13 85 302 95
295 4 -2 5 6 1 18 -4 16 -2 19 8 13 9 -5 11 -4 6 3 -10 16 15 93 27 85 6 -4 8
0 4 9 -7 19 13 89 24 83 4 -3 5 5 1 17 -3 11 -1 19 4 16 5 -4 10 8 10 25 1 17
6 33 12 35 6 2 8 10 5 19 -4 10 -2 14 4 10 7 -5 9 0 6 13 -3 11 -1 20 5 20 5
0 10 6 9 13 -1 34 4 48 15 41 7 -4 8 -3 4 4 -9 15 84 303 96 296 4 -3 5 2 2
10 -3 8 -1 23 5 33 7 13 10 -277 11 -984 l0 -1003 235 0 235 0 0 1605 0 1605
-237 -2 -236 -3 -29 -84 c-15 -47 -31 -87 -35 -90 -5 -3 -8 -11 -8 -17 0 -23
-59 -193 -66 -189 -4 3 -5 -5 -1 -17 4 -16 2 -19 -8 -13 -9 5 -11 4 -6 -3 10
-16 -25 -123 -37 -115 -6 3 -7 1 -3 -5 8 -14 -14 -93 -25 -87 -4 3 -5 -5 -1
-17 3 -14 1 -19 -6 -14 -7 4 -10 2 -7 -6 6 -19 -69 -230 -80 -221 -4 4 -5 2
-1 -4 10 -17 -14 -94 -27 -86 -6 3 -7 1 -3 -5 10 -16 -25 -123 -37 -115 -6 3
-7 1 -3 -6 9 -14 -52 -201 -65 -201 -6 0 -8 -4 -5 -9 9 -13 -58 -203 -68 -194
-5 4 -6 2 -2 -5 8 -14 -54 -213 -65 -206 -4 2 -5 -5 -2 -16 3 -11 1 -20 -5
-20 -14 0 -25 37 -16 52 4 7 3 10 -1 5 -11 -9 -78 172 -70 191 3 8 0 10 -7 6
-7 -5 -9 0 -6 14 4 12 2 20 -2 17 -12 -7 -53 122 -44 136 4 7 3 9 -3 6 -13 -8
-177 448 -167 464 4 7 3 9 -2 6 -13 -7 -207 539 -198 555 4 7 3 9 -1 5 -10 -9
-68 140 -59 154 3 5 1 9 -3 9 -13 0 -35 68 -27 81 4 7 3 9 -3 6 -6 -4 -14 4
-17 18 l-6 25 -235 0 -235 0 -2 -1367 -3 -1368 -560 0 -560 0 0 2955 0 2955
10018 3 10017 2 -2 -2957 -3 -2958 -655 0 -655 0 -18 70 c-9 39 -16 80 -14 92
2 12 1 21 -2 19 -4 -3 -21 62 -38 142 -18 81 -78 354 -134 607 -56 253 -102
476 -103 497 0 20 -3 34 -6 32 -7 -8 -101 429 -96 449 2 9 1 15 -3 13 -4 -3
-47 179 -96 405 l-89 409 -208 0 -207 0 -10 -37 c-5 -21 -51 -230 -102 -465
-54 -249 -97 -424 -102 -421 -6 4 -7 1 -2 -6 9 -15 -56 -322 -67 -315 -4 3 -5
-2 -2 -10 7 -18 -86 -450 -98 -454 -4 -2 -6 -8 -3 -13 9 -13 -47 -259 -57
-253 -4 3 -5 -2 -2 -10 7 -20 -67 -366 -78 -360 -4 3 -5 -1 -2 -9 3 -8 -35
-201 -85 -428 -50 -228 -91 -417 -91 -422 0 -4 105 -7 234 -7 215 0 235 1 240
18 6 18 146 651 146 658 0 3 129 3 287 2 l287 -3 74 -338 75 -337 1079 0 1078
0 0 3430 0 3430 -10490 0 -10490 0 0 -3430z m18399 -1259 c7 -39 9 -76 5 -82
-4 -7 -3 -9 3 -6 12 7 58 -227 47 -244 -4 -7 -3 -10 2 -6 13 7 99 -416 89
-433 -5 -8 -4 -11 2 -7 5 3 19 -44 32 -111 12 -64 25 -129 28 -144 l6 -28
-232 0 c-217 0 -232 1 -227 18 10 34 206 1050 206 1066 0 10 4 15 9 12 4 -3 6
6 3 21 -3 15 -1 24 5 20 5 -3 15 -38 22 -76z"/>
<path d="M6082 6025 c-23 -7 -54 -21 -68 -30 -18 -11 -24 -13 -19 -4 5 8 -10
-6 -33 -31 -67 -73 -83 -117 -89 -247 l-6 -113 117 0 116 0 0 75 c0 67 3 78
28 105 15 17 34 32 42 33 8 2 21 6 28 11 21 13 85 -4 107 -28 11 -12 16 -15
10 -7 -8 11 -7 13 4 7 24 -15 41 -75 41 -149 0 -90 -9 -139 -23 -130 -6 3 -7
1 -3 -6 10 -16 -32 -57 -77 -76 -20 -8 -57 -15 -82 -15 l-45 0 0 -100 0 -100
49 0 c64 0 100 -14 134 -52 40 -46 49 -89 45 -226 l-3 -123 -34 -30 c-41 -35
-69 -44 -118 -34 -78 14 -103 51 -103 150 l0 65 -116 0 -117 0 5 -108 c6 -124
25 -179 85 -246 21 -22 39 -40 40 -39 1 1 20 -6 40 -15 30 -14 68 -17 184 -17
142 0 147 1 204 30 70 36 118 93 140 167 21 71 21 364 0 434 -14 47 -75 136
-92 134 -18 -3 -13 22 6 27 11 3 30 21 42 39 11 19 25 34 30 34 5 0 8 3 7 8
-1 4 4 33 11 65 15 68 13 379 -3 370 -5 -4 -7 1 -3 11 4 9 3 15 -1 12 -5 -3
-19 16 -33 41 -13 25 -34 49 -47 54 -13 5 -21 13 -18 17 2 4 -3 8 -12 9 -9 1
-42 11 -72 22 -66 24 -229 28 -298 6z"/>
<path d="M14160 6034 c-43 -16 -88 -39 -115 -58 -16 -11 -34 -21 -40 -21 -5 0
-10 -5 -10 -11 0 -15 -31 -44 -40 -36 -4 4 -5 2 -4 -3 4 -15 -11 -56 -19 -51
-4 2 -8 -6 -8 -17 -1 -12 -7 -51 -13 -87 -14 -87 -15 -828 0 -923 16 -106 69
-186 157 -238 23 -14 40 -27 38 -30 -8 -7 57 -12 174 -13 100 -1 121 2 159 21
24 13 47 20 52 18 4 -3 11 0 15 6 5 8 2 10 -7 4 -8 -4 2 6 21 23 19 16 39 27
44 24 5 -3 6 -1 3 5 -6 10 46 96 54 89 9 -9 10 52 15 509 5 481 1 559 -33 611
-8 13 -12 28 -8 34 4 6 3 9 -2 8 -4 -2 -30 19 -56 45 -75 72 -121 90 -252 93
-60 2 -117 1 -125 -2z m199 -231 c61 -45 61 -47 60 -513 -1 -234 -4 -436 -8
-449 -3 -13 -23 -38 -44 -55 -32 -26 -47 -30 -96 -31 -48 0 -64 5 -94 27 -55
43 -59 75 -55 540 3 433 4 440 56 474 56 37 136 40 181 7z"/>
<path d="M17414 6021 c-2 -2 -4 -332 -4 -733 l0 -728 110 0 110 0 0 285 0 285
80 0 c192 0 322 71 381 208 64 149 42 443 -41 549 -11 14 -17 29 -14 34 3 5 2
8 -3 7 -4 -2 -28 11 -53 28 -79 54 -98 58 -338 64 -123 3 -226 3 -228 1z m417
-223 c24 -14 38 -27 34 -36 -2 -7 -1 -10 5 -7 21 13 30 -41 30 -182 0 -119 -3
-153 -16 -171 -8 -12 -20 -19 -26 -16 -7 4 -8 2 -4 -4 13 -21 -56 -45 -141
-50 l-83 -5 0 247 0 246 81 0 c65 0 89 -4 120 -22z"/>
<path d="M2350 5290 l0 -730 110 0 110 0 0 453 0 452 108 -325 108 -325 64 0
63 0 21 63 c12 35 25 60 30 55 5 -4 6 -2 2 5 -4 7 29 123 76 264 l83 252 3
-447 2 -447 110 0 110 0 0 730 0 730 -115 0 c-97 0 -115 -2 -115 -16 0 -8 -3
-13 -7 -11 -5 3 -8 -7 -9 -21 0 -15 -53 -178 -116 -362 -149 -432 -132 -391
-147 -341 -6 23 -9 47 -5 53 4 6 3 8 -4 4 -13 -8 -57 119 -46 135 4 7 3 9 -3
6 -7 -5 -107 245 -117 295 -2 7 -7 22 -12 33 -4 11 -24 65 -44 120 l-37 100
-111 3 -112 3 0 -731z"/>
<path d="M3777 5953 c-8 -38 -28 -127 -43 -200 -16 -72 -31 -130 -35 -127 -4
2 -5 -4 -2 -14 2 -10 -5 -56 -16 -103 -11 -46 -34 -152 -52 -236 -18 -84 -36
-150 -41 -147 -4 3 -5 -2 -2 -10 5 -13 -38 -230 -86 -433 -32 -132 -38 -123
89 -123 l109 0 11 53 c6 28 21 96 33 149 l22 97 128 3 127 3 11 -50 c5 -27 20
-96 32 -152 l23 -103 113 0 c103 0 113 2 108 18 -21 70 -86 397 -82 408 3 9 1
12 -4 9 -6 -3 -10 2 -10 11 0 10 -7 45 -15 78 -14 61 -36 161 -140 636 -30
140 -57 265 -60 278 -5 21 -10 22 -104 22 l-99 0 -15 -67z m162 -637 c28 -137
51 -250 51 -252 0 -2 -45 -4 -100 -4 -55 0 -100 3 -100 8 0 4 20 108 45 232
25 124 45 236 45 249 0 13 2 22 4 20 2 -2 27 -116 55 -253z"/>
<path d="M4440 5290 l0 -730 110 0 110 0 0 625 0 625 210 0 210 0 0 105 0 105
-320 0 -320 0 0 -730z"/>
<path d="M5281 5983 c-5 -21 -38 -167 -72 -325 -34 -158 -65 -285 -69 -283 -4
2 -4 -10 -2 -27 4 -23 2 -29 -8 -23 -9 6 -11 4 -5 -4 9 -15 -145 -732 -156
-725 -4 2 -5 -5 -2 -16 5 -19 12 -20 117 -18 l111 3 31 135 c16 75 34 141 38
148 5 8 45 12 130 12 l122 0 13 -62 c7 -35 21 -102 32 -150 l21 -88 109 0 c89
0 109 3 109 15 0 8 -18 96 -40 197 -23 101 -39 191 -36 201 2 9 0 15 -4 12
-11 -7 -24 71 -14 86 4 7 3 9 -3 6 -12 -8 -67 248 -57 265 5 7 3 8 -6 3 -10
-6 -12 0 -7 31 4 21 3 33 -1 26 -5 -7 -15 18 -25 65 -9 43 -40 185 -68 316
l-52 237 -98 0 -98 0 -10 -37z m124 -560 c-2 -7 -1 -13 3 -13 11 0 44 -178 36
-192 -5 -7 -3 -8 6 -3 10 6 12 0 7 -31 -4 -21 -3 -33 2 -26 5 8 11 -8 18 -43
l10 -55 -100 0 -100 0 7 38 c3 20 6 45 6 55 0 10 4 15 10 12 5 -3 7 0 4 9 -3
8 12 99 32 203 l38 188 13 -65 c7 -35 11 -70 8 -77z"/>
<path d="M6798 6003 c-2 -9 -2 -337 0 -730 l2 -713 110 0 110 0 0 91 c0 66 3
88 12 83 7 -4 8 -3 4 5 -10 16 84 212 97 204 6 -4 7 -1 2 6 -10 17 35 112 48
104 6 -3 7 -1 4 5 -10 15 3 43 16 35 6 -4 7 -1 2 6 -10 17 35 112 48 104 6 -3
7 -1 3 5 -8 13 14 63 25 56 5 -3 6 3 2 12 -4 12 -3 15 5 10 8 -4 10 -2 6 7 -3
8 8 41 25 73 l30 59 1 -432 0 -433 110 0 110 0 0 730 0 730 -110 0 -110 0 0
-81 0 -81 -131 -279 c-73 -153 -136 -276 -140 -273 -5 3 -7 -1 -4 -8 3 -7 -8
-40 -24 -73 l-29 -60 -1 428 -1 427 -109 0 c-95 0 -110 -2 -113 -17z"/>
<path d="M7800 5290 l0 -730 115 0 115 0 0 320 0 320 130 0 130 0 0 -320 0
-320 110 0 110 0 0 730 0 730 -110 0 -110 0 0 -310 0 -310 -130 0 -130 0 0
310 0 310 -115 0 -115 0 0 -730z"/>
<path d="M9230 5289 l0 -730 245 2 c134 1 242 6 239 11 -5 7 13 12 44 11 7 -1
11 1 8 4 -3 3 13 19 35 35 49 35 99 105 116 161 19 61 27 250 15 330 -7 41
-22 87 -39 115 -15 26 -26 50 -25 54 1 5 -1 7 -6 6 -4 -2 -17 4 -29 13 l-22
15 48 52 c63 67 76 114 75 263 -2 101 -5 123 -29 180 -15 35 -27 69 -27 76 -1
7 -4 10 -8 8 -4 -3 -19 9 -34 25 -14 17 -31 30 -37 30 -5 0 -8 4 -5 8 3 5 0 9
-7 10 -7 1 -43 12 -82 26 -66 24 -82 25 -272 26 l-203 0 0 -731z m366 522 c-4
-5 3 -7 13 -4 26 7 84 -34 77 -54 -3 -8 -2 -11 4 -8 10 6 30 -56 30 -96 0 -48
-13 -119 -20 -114 -5 3 -6 -4 -3 -14 6 -23 -41 -75 -81 -91 -15 -5 -56 -10
-92 -10 l-64 0 0 200 0 200 71 0 c39 0 68 -4 65 -9z m51 -617 c54 -41 67 -76
67 -193 0 -86 -4 -115 -21 -153 -29 -63 -59 -81 -154 -88 l-79 -5 0 233 0 234
79 -3 c61 -3 86 -9 108 -25z"/>
<path d="M10356 5985 c-4 -20 -10 -33 -15 -31 -4 3 -6 -7 -4 -22 4 -35 -82
-421 -90 -402 -3 8 -3 0 0 -19 4 -23 3 -32 -5 -28 -6 4 -8 0 -6 -10 3 -10 -38
-213 -91 -451 -52 -239 -95 -440 -95 -448 0 -11 22 -14 109 -14 l110 0 21 98
c12 53 28 120 34 147 l12 50 126 2 127 1 21 -96 c12 -53 27 -119 34 -147 l12
-50 112 -3 c62 -1 112 0 112 2 0 3 -36 170 -81 371 -44 202 -78 373 -75 381 3
8 2 13 -2 10 -11 -7 -95 390 -86 406 4 8 3 10 -2 5 -5 -5 -22 52 -40 135 l-31
143 -100 3 -101 3 -6 -36z m153 -663 c23 -117 39 -223 36 -235 -5 -17 -4 -19
4 -7 9 13 11 13 11 -2 0 -16 -11 -18 -101 -18 -100 0 -101 0 -96 23 3 12 16
75 28 139 13 64 26 115 31 112 4 -3 5 2 2 10 -8 20 27 209 36 199 4 -5 26
-104 49 -221z"/>
<path d="M11010 5290 l0 -730 550 0 550 0 0 730 0 730 -115 0 -115 0 0 -630 0
-630 -105 0 -105 0 0 630 0 630 -110 0 -110 0 0 -630 0 -630 -110 0 -110 0 0
630 0 630 -110 0 -110 0 0 -730z"/>
<path d="M12340 5290 l0 -730 320 0 320 0 0 105 0 105 -210 0 -210 0 0 215 0
215 185 0 186 0 -3 98 -3 97 -182 3 -183 2 0 205 0 205 205 0 205 0 0 105 0
105 -315 0 -315 0 0 -730z"/>
<path d="M13160 5290 l0 -730 115 0 115 0 0 625 0 625 210 0 210 0 0 105 0
105 -325 0 -325 0 0 -730z"/>
<path d="M15350 5290 l0 -730 110 0 110 0 1 453 0 452 20 -60 c11 -33 17 -65
14 -73 -2 -7 -1 -11 4 -8 11 7 24 -41 15 -56 -4 -6 -3 -8 3 -5 7 4 70 -161 78
-203 0 -3 11 -35 24 -72 12 -37 19 -73 15 -80 -4 -7 -3 -10 1 -5 5 4 16 -14
25 -40 l16 -48 64 0 64 0 105 320 106 320 3 -447 2 -448 110 0 110 0 0 730 0
730 -113 0 -113 0 -24 -75 c-13 -42 -28 -71 -33 -68 -6 3 -7 1 -3 -6 10 -15
-134 -442 -145 -435 -5 3 -6 -1 -3 -9 2 -7 -7 -47 -21 -88 -15 -41 -29 -81
-31 -89 -5 -13 -6 -13 -14 0 -20 36 -140 391 -135 399 3 5 0 11 -6 13 -5 2
-19 28 -29 58 -10 30 -35 100 -55 154 -19 55 -33 106 -30 114 2 7 0 10 -5 7
-6 -3 -10 1 -10 9 0 14 -18 16 -115 16 l-115 0 0 -730z"/>
<path d="M16580 5300 c0 -396 -1 -723 -2 -727 -2 -5 142 -9 320 -11 l322 -2 0
105 0 105 -188 0 c-137 0 -192 3 -201 13 -11 10 -13 10 -7 0 4 -7 2 -13 -3
-13 -8 0 -11 70 -11 215 l0 215 180 0 180 0 0 100 0 100 -180 0 -180 0 0 205
0 205 205 0 205 0 0 105 0 105 -320 0 -320 0 0 -720z"/>
<path d="M18270 5707 c0 -323 7 -396 46 -454 8 -13 12 -23 9 -23 -12 0 34 -37
70 -57 76 -41 219 -53 313 -27 l22 6 0 -296 0 -296 115 0 115 0 0 730 0 730
-115 0 -115 0 0 -330 c0 -372 6 -345 -82 -356 -51 -6 -101 16 -132 59 -20 27
-21 43 -24 328 l-3 299 -110 0 -109 0 0 -313z"/>
<path d="M19381 5904 c-16 -78 -27 -111 -33 -102 -6 9 -7 5 -3 -10 8 -26 -66
-373 -77 -366 -4 3 -5 -1 -2 -9 4 -13 -39 -220 -151 -726 -14 -63 -25 -118
-25 -123 0 -5 50 -7 111 -6 l111 3 18 70 c9 39 20 88 24 110 4 22 11 56 17 76
l9 37 125 1 124 2 10 -38 c6 -21 19 -81 31 -133 11 -52 23 -103 26 -112 5 -16
19 -18 115 -18 63 0 109 4 109 10 0 5 -41 195 -90 422 -50 227 -89 421 -86
431 2 9 1 15 -3 13 -4 -3 -36 128 -72 290 l-64 294 -100 0 -101 0 -23 -116z
m175 -647 c-2 -9 -1 -15 3 -13 8 5 46 -172 38 -180 -2 -3 -47 -3 -99 -2 l-94
3 43 220 c23 121 45 231 49 245 5 18 15 -12 36 -115 16 -77 27 -148 24 -158z"/>
<path d="M6591 5754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8468 4543 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5535 3856 c-49 -13 -94 -21 -100 -18 -5 3 -7 2 -3 -3 4 -4 -15 -18
-43 -31 -28 -13 -77 -42 -109 -65 -56 -39 -150 -123 -150 -134 0 -3 -16 -27
-34 -53 -48 -66 -74 -131 -99 -242 -20 -91 -21 -129 -24 -995 -5 -918 -1
-1033 34 -1190 8 -38 31 -100 49 -136 36 -67 138 -199 155 -199 6 0 7 -5 4
-10 -4 -6 -3 -9 2 -8 8 3 86 -51 91 -64 2 -5 10 -8 18 -8 8 0 14 -5 14 -10 0
-6 3 -9 8 -9 19 3 33 -2 27 -10 -3 -6 3 -10 12 -9 10 0 50 -12 88 -26 107 -38
200 -49 355 -42 177 9 189 10 184 15 -7 8 109 40 119 34 6 -4 7 -1 2 7 -6 10
-4 12 9 7 9 -4 15 -3 12 2 -7 11 22 23 36 14 6 -3 8 -2 5 4 -4 6 6 16 20 22
15 7 45 27 67 44 22 18 42 31 44 29 1 -2 21 18 44 42 22 25 45 43 51 39 5 -3
7 -1 4 4 -4 6 13 41 38 77 24 37 50 85 56 106 7 22 15 40 18 40 4 0 7 8 7 18
0 9 8 71 18 137 16 109 17 210 14 1060 -4 910 -5 943 -25 1030 -21 93 -56 175
-100 233 -13 17 -22 32 -19 32 10 0 -124 124 -168 155 -25 18 -42 36 -38 40 4
5 2 5 -5 1 -13 -7 -103 33 -103 46 0 4 -7 4 -17 1 -9 -4 -14 -2 -11 3 4 5 -4
10 -15 10 -12 1 -53 9 -92 18 -111 25 -336 21 -450 -8z m395 -470 c30 -15 68
-39 83 -54 39 -37 80 -109 73 -128 -3 -8 -2 -12 3 -9 19 11 22 -125 19 -1005
l-3 -906 -26 -49 c-14 -28 -32 -49 -38 -47 -7 1 -10 -2 -7 -7 3 -5 -3 -20 -15
-32 -11 -13 -17 -17 -13 -9 4 8 -5 2 -19 -13 -15 -16 -32 -25 -39 -21 -6 4 -8
3 -4 -4 12 -20 -84 -47 -169 -47 -68 0 -89 5 -140 29 -63 31 -133 93 -158 142
-34 67 -37 138 -37 1003 0 829 3 966 21 955 5 -3 6 1 3 9 -6 16 9 58 28 78 7
8 21 26 31 42 10 15 22 27 27 27 6 0 19 9 31 21 11 11 27 18 35 15 9 -3 12 -1
9 4 -3 6 -5 10 -3 10 2 0 21 7 43 15 66 25 197 15 265 -19z"/>
<path d="M12745 3865 c-58 -13 -113 -33 -200 -71 -22 -10 -44 -17 -50 -15 -5
1 -7 0 -3 -4 4 -4 -19 -26 -50 -50 -104 -77 -204 -205 -237 -306 -48 -142 -48
-152 -52 -1104 -3 -622 -1 -934 7 -1010 22 -220 57 -315 161 -441 32 -39 55
-75 51 -79 -4 -5 -1 -5 6 -1 13 8 56 -27 46 -36 -2 -3 2 -5 9 -5 6 0 39 -17
71 -37 33 -20 64 -36 69 -36 4 0 6 -4 3 -8 -2 -4 3 -8 12 -9 9 -1 55 -14 102
-29 76 -24 101 -27 240 -28 154 -1 290 5 268 13 -6 2 15 12 47 22 32 10 63 15
68 12 6 -3 7 -1 4 4 -8 13 20 26 35 16 6 -3 8 -2 5 4 -4 6 13 19 36 29 23 10
46 24 50 31 5 8 12 11 18 8 5 -4 9 -2 9 3 0 5 20 25 45 45 54 42 138 152 172
225 14 29 34 94 46 145 20 90 21 117 24 1030 3 811 2 954 -12 1052 -8 62 -22
135 -30 161 -8 26 -11 52 -8 57 3 6 2 7 -3 4 -5 -3 -23 20 -39 51 -46 88 -191
228 -290 279 -142 73 -196 86 -395 89 -123 2 -193 -1 -235 -11z m307 -455 c21
-6 45 -18 54 -28 10 -9 19 -16 21 -14 20 15 105 -69 136 -135 l22 -48 3 -914
c2 -599 -1 -933 -8 -970 -10 -53 -75 -169 -88 -156 -3 3 -11 -4 -19 -15 -8
-11 -21 -20 -29 -20 -8 0 -14 -5 -14 -11 0 -6 -7 -8 -16 -5 -8 3 -13 2 -10 -3
8 -12 -48 -31 -114 -38 -67 -7 -163 16 -217 51 -40 25 -113 96 -99 96 4 0 0 7
-8 15 -40 40 -41 64 -41 1025 0 758 2 932 14 965 16 47 88 148 100 140 5 -3
12 2 15 11 4 9 13 14 22 10 8 -3 13 -2 10 3 -7 11 22 23 36 14 7 -3 8 -1 4 5
-16 26 151 42 226 22z"/>
<path d="M17735 3869 c-38 -5 -90 -16 -114 -24 -24 -8 -48 -12 -54 -8 -6 4 -7
1 -2 -7 6 -10 4 -12 -9 -7 -9 4 -15 3 -12 -2 3 -5 -7 -17 -23 -28 -104 -67
-159 -157 -192 -317 -10 -50 -32 -211 -49 -358 -46 -409 -70 -516 -124 -554
-12 -8 -19 -18 -17 -22 3 -4 -15 -20 -38 -37 -42 -29 -45 -30 -187 -33 -116
-3 -144 -1 -144 10 0 10 -3 9 -10 -2 -6 -10 -10 225 -10 673 l0 687 -235 0
-235 0 0 -1605 0 -1605 235 0 235 0 0 710 0 710 177 -2 178 -3 88 -310 c48
-170 89 -324 90 -341 1 -18 5 -30 8 -28 4 2 10 -13 15 -34 4 -20 9 -41 10 -47
50 -166 71 -244 69 -262 -1 -12 1 -20 5 -18 11 7 43 -122 34 -136 -5 -8 -3 -9
6 -4 10 6 12 3 8 -13 -4 -12 -3 -20 1 -18 3 2 19 -43 35 -100 l28 -104 254 0
254 0 -2 44 c-2 24 -5 42 -7 40 -9 -9 -211 681 -205 701 3 13 2 16 -3 7 -12
-15 -38 82 -27 99 4 7 3 9 -3 6 -12 -7 -25 32 -20 55 2 7 2 9 -1 4 -7 -15 -63
177 -56 194 3 8 2 11 -2 7 -9 -9 -65 173 -59 191 3 7 1 11 -3 9 -4 -3 -27 62
-51 145 l-44 150 26 23 c15 12 27 27 27 32 0 5 8 18 18 29 10 10 19 22 20 27
1 4 10 35 20 70 21 68 29 123 67 481 31 289 53 406 77 406 5 0 6 5 3 10 -3 6
-1 10 5 10 6 0 9 4 5 9 -15 25 101 81 168 81 l27 0 0 210 0 210 -92 -1 c-51
-1 -124 -5 -163 -10z"/>
<path d="M7580 3831 c0 -3 -35 -14 -79 -24 -121 -27 -229 -84 -309 -163 -37
-37 -70 -67 -73 -66 -3 0 -6 -4 -6 -11 -1 -23 -70 -137 -78 -130 -4 5 -5 3 -1
-4 7 -12 -24 -124 -33 -118 -3 2 -6 -9 -7 -23 0 -15 -6 -74 -13 -131 -9 -70
-12 -171 -8 -310 4 -180 8 -219 31 -305 14 -54 23 -103 19 -109 -3 -5 -1 -7 4
-4 10 6 50 -79 45 -96 -1 -5 1 -6 6 -3 10 6 37 -43 33 -59 -2 -5 1 -7 5 -3 3
4 20 -9 36 -30 25 -31 137 -113 169 -124 6 -2 8 -15 4 -31 -4 -15 -12 -24 -18
-20 -6 3 -7 1 -3 -5 9 -15 -4 -63 -15 -56 -5 3 -6 -2 -3 -10 6 -16 -61 -231
-80 -255 -6 -8 -9 -25 -8 -39 3 -17 0 -22 -9 -17 -8 5 -10 4 -5 -3 8 -13 -34
-153 -43 -147 -3 1 -7 -11 -7 -28 -1 -17 -6 -33 -12 -35 -6 -2 -9 -9 -7 -15 7
-18 -120 -399 -130 -390 -4 5 -5 2 -1 -4 10 -17 -14 -94 -27 -86 -6 3 -7 1 -3
-5 8 -13 -33 -164 -43 -158 -3 2 -7 -10 -7 -27 -1 -17 -6 -33 -12 -35 -6 -2
-8 -10 -5 -18 3 -8 1 -14 -3 -14 -5 0 -10 -12 -11 -27 0 -15 -6 -29 -12 -31
-5 -2 -7 -10 -4 -18 4 -12 48 -14 259 -14 223 0 254 2 254 16 0 9 5 12 12 8 7
-5 8 -3 3 5 -10 17 56 242 68 234 6 -3 7 -1 3 6 -10 16 115 432 127 424 6 -4
7 -1 2 6 -8 14 86 342 96 335 4 -2 5 6 1 18 -3 13 -1 19 5 15 6 -4 8 4 5 20
-3 18 -1 24 6 19 7 -4 10 -2 7 6 -3 7 12 69 33 138 l38 125 132 3 132 3 0
-675 c0 -371 3 -681 6 -690 5 -14 36 -16 240 -16 l234 0 0 1606 0 1605 -470
-2 c-258 -1 -470 -5 -470 -8z m460 -906 l0 -495 -118 0 c-128 0 -214 8 -207
20 2 4 -7 5 -22 2 -18 -3 -24 -1 -19 6 5 8 1 10 -9 6 -19 -8 -128 63 -119 77
3 5 0 8 -6 7 -7 -2 -17 6 -23 16 -7 11 -20 27 -29 37 -9 10 -22 35 -28 56 -18
56 -25 377 -11 458 15 83 40 147 54 138 6 -4 7 -1 2 7 -5 8 1 20 16 32 13 11
17 16 9 12 -10 -5 -9 0 4 14 10 12 23 19 29 15 6 -3 7 -1 3 5 -4 6 13 22 40
38 55 32 115 41 301 43 l132 1 1 -495z"/>
<path d="M10112 2238 l3 -1603 233 -3 232 -2 0 1395 0 1395 290 0 290 0 0
-1395 0 -1395 235 0 235 0 0 1605 0 1605 -760 0 -760 0 2 -1602z"/>
<path d="M14446 3353 c-12 -922 -55 -1667 -112 -1939 -19 -95 -68 -209 -85
-198 -7 4 -9 3 -6 -3 9 -15 -59 -79 -111 -105 -31 -14 -71 -23 -123 -26 -60
-3 -79 -8 -81 -20 -2 -8 -2 -109 0 -224 l3 -208 82 0 c45 0 125 8 177 17 52 9
110 17 128 17 18 0 27 3 21 7 -7 4 -3 9 12 14 13 4 45 20 71 35 25 16 49 26
54 22 4 -4 4 -1 0 6 -7 11 6 17 29 12 5 -1 7 2 3 6 -4 4 26 43 67 86 41 44 75
84 75 89 0 6 5 7 10 4 6 -4 9 -3 8 2 -1 4 17 46 40 93 125 254 157 554 199
1853 l17 527 173 0 173 0 2 -1392 3 -1393 233 -3 232 -2 0 1605 0 1605 -644 0
-644 0 -6 -487z"/>
</g>
</svg>

</template>
